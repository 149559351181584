<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav" @tab-click="handleClick">
      <el-tab-pane label="全局奖励" name="first">
        <!--      示例-->
        <div class="example flex-nowrap">
          <img src="~assets/images/rule/example.png" class="example-icon" alt="">
          示例：课程-观看时长1小时-5金币 即观课程观看时长达一小时获得5金币；不选择时长则默认进入就获得金币
        </div>

        <!--      表单-->
        <el-form :model="rulesInfo" ref="first" class="demo-dynamic" v-loading="loading">
          <div v-for="(domain, index) in rulesInfo.rulesData" :key="index" class="rule-row flex-nowrap">
            <el-form-item :label="'规则' + (index +1) " label-width="70px"   :rules="{required: true, message: '请选择规则', trigger: 'blur' }" :prop="'rulesData.' + index + '.code'">
              <el-select v-model="domain.code" placeholder="请选择类型"  @visible-change="dictionaryBlur($event, index)" :disabled="domain.id ? true: false">
                <el-option
                    v-for="(item,index) in dictionaryData"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label='观看时长' label-width="82px" class="form-center" :rules="{required: true, message: '请输入时长', trigger: 'blur', type: 'number', message: '时长必须为数字值'}" :prop="'rulesData.' + index + '.details.time'" v-if="domain.code !== 'circle' && domain.code !== 'comment' && domain.code !== 'reply' && domain.code !== 'course'">
              <el-input v-if="domain.details"  v-model.number="domain.details.time" placeholder="请输入时长" maxlength="3" style="width: 100px" class="form-center-left"></el-input>
              <el-select v-if="domain.details" v-model="domain.details.unit" placeholder="单位" class="company">
                <el-option
                    v-for="item in timeData"
                    :key="item.label"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="+金币数" label-width="80px" class="form-end "  :rules="{required: true, message: '请输入金币数', trigger: 'blur' ,pattern: /^([1-9][0-9]*)$/, message: '金币数为大于0且小于999的数字'}" :prop="'rulesData.' + index + '.details.val'">
              <el-input v-if="domain.details" v-model.number="domain.details.val" maxlength="3" placeholder="请输入金币数"></el-input>
              <el-button icon="el-icon-plus" @click.prevent="addDomain" class="form-btn-right f-r" v-if="addDisplay && rulesInfo.rulesData.length === index + 1"></el-button>
              <el-button icon="el-icon-minus" @click.prevent="removeDomain(index, 'all')" class="form-btn-left f-r" ></el-button>

            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="内容奖励" name="two">
        <!--      示例-->
        <div class="example flex-nowrap">
          <img src="~assets/images/rule/example.png" class="example-icon" alt="">
          示例：课程-观看时长1小时-5金币 即观课程观看时长达一小时获得5金币；不选择时长则默认进入就获得金币
        </div>
        <el-form :model="rulesInfo" ref="two" class="demo-dynamic" v-loading="loading">
          <div v-for="(domain, index) in rulesInfo.rulesData" :key="index" class="rule-row flex-nowrap">
            <el-form-item :label="'规则' + (index +1) " label-width="70px"   :rules="{required: true, message: '请选择规则', trigger: 'blur' }" :prop="'rulesData.' + index + '.code'">
              <el-select v-model="domain.code" placeholder="请选择类型"  @visible-change="dictionaryBlur($event, index)" :disabled="domain.id ? true: false">
                <el-option
                  v-for="(item,inde) in contentData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label='观看时长' label-width="82px" class="form-center" :rules="{required: true, message: '请输入时长', trigger: 'blur', type: 'number', message: '时长必须为数字值'}" :prop="'rulesData.' + index + '.details.time'" v-if="domain.type !== 'comment' && domain.type !== 'reply'">
              <el-input v-if="domain.details"  v-model.number="domain.details.time" placeholder="请输入时长" maxlength="3" style="width: 100px" class="form-center-left"></el-input>
              <el-select v-if="domain.details" v-model="domain.details.unit" placeholder="单位" class="company">
                <el-option
                  v-for="item in timeData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="+金币数" label-width="80px" class="form-end "  :rules="{required: true, message: '请输入金币数', trigger: 'blur' ,pattern: /^([1-9][0-9]*)$/, type: 'number', message: '金币数为大于0且小于999的数字'}" :prop="'rulesData.' + index + '.details.val'">
              <el-input v-if="domain.details" v-model.number="domain.details.val" maxlength="3" placeholder="请输入金币数"></el-input>
              <el-button icon="el-icon-plus" @click.prevent="addDomain" class="form-btn-right f-r" v-if="addDisplay && rulesInfo.rulesData.length === index + 1"></el-button>
              <el-button icon="el-icon-minus" @click.prevent="removeDomain(index, 'content')" class="form-btn-left f-r" v-if="rulesInfo.rulesData.length !== 1"></el-button>

            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="最多奖励" name="three">
        <!--      示例-->
        <div class="example flex-nowrap">
          <img src="~assets/images/rule/example.png" class="example-icon" alt="">
          示例：课程-观看时长1小时-5金币 即观课程观看时长达一小时获得5金币；不选择时长则默认进入就获得金币
        </div>
        <el-form :model="rulesInfo" ref="three" class="demo-dynamic">
          <div class="rule-top flex-nowrap">
            <el-form-item label='最多奖励' label-width="85px" :prop="'control_config.time'" class="form-center" :rules="{required: true, message: '请输入时长', trigger: 'blur', type: 'number', message: '时长必须为数字值'}" >
              <el-input v-model.number="rulesInfo.control_config.time" placeholder="请输入时长" maxlength="3" style="width: 140px" class="form-center-left"></el-input>
              <el-select v-model="rulesInfo.control_config.unit" placeholder="单位" class="company">
                <el-option
                  v-for="item in unitData"
                  :key="item.label"
                  :label="item.label"
                  :value="item.value">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="可获得" label-width="100px" class="form-end" :prop="'control_config.val'"  :rules="{required: true, message: '请输入金币数', trigger: 'blur' ,pattern: /^([1-9][0-9]*)$/, type: 'number', message: '金币数为大于0且小于999的数字'}">
              <el-input v-model.number="rulesInfo.control_config.val" maxlength="3" placeholder="请输入金币数"></el-input>
            </el-form-item>
          </div>
        </el-form>
      </el-tab-pane>

    </el-tabs>
    <div class="form-operation">
      <el-button class="form-operation-item" @click="cancelBtn" round>取消</el-button>
      <el-button class="form-operation-item" type="primary" @click="saveSubmit" round>保存</el-button>
    </div>
  </div>
</template>

<script>
import _ from "lodash"
export default {
  name: "Index",
  data() {
    return {
      loading:true,
      activeName: 'first',
      rulesInfo: {
        rulesData: [
          {
            code:'',
            details: {
              time: '',
              unit: '',
              val: ''
            }
          }
        ],
        control_config: {
          time: '',
          unit: 'd',
          val: ''
        },  // 最多奖励控制对象
      },  // 列表信息
      dictionaryData: [],//全局
      contentData:[],//内容
      timeData: [
        {
          label: '时',
          value: 'h'
        },
        {
          label: '分',
          value: 'm'
        },
        {
          label: '秒',
          value: 's'
        }
      ],  // 筛选时间提交
      unitData: [
        {
          label: '日',
          value: 'd'
        },
        {
          label: '周',
          value: 'w'
        },
      ],
      save_submit_status: true,  // 保存提交状态
      ruleid:'',//rule Id
      dictionary_default: [], // 默认值
      content_default:[],
      alldata:[],//全局奖励
      addDisplay:true,
    }
  },
  created() {
    this.queryFun()
    this.Dictionary()
    this.getRules()
  },
  methods: {
    // 获取规则列表
    getRules() {
      let url = this.$api.Rules + '?rule_type=1';
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          let rules_data= res.data.data
          this.ruleid = res.data.data.id
          // this.getRlueInfo(1)
          this.tabsrule()
          if(rules_data.details && rules_data.details.length) {
            this.rulesInfo.rulesData= rules_data.details
          }
          if(rules_data.control_config) {
            this.rulesInfo.control_config= rules_data.control_config
          }
        }
      })
    },
    // 规则全局
    getRlueInfo(val){
      this.loading = true
      let url = this.$api.Rules + '/'+ this.ruleid + this.$api.ruleItem + `?is_global=${val}`;
      this.$http.get(url, true).then(res => {
        if (res.data.success) {
          if (res.data.data.length){
            this.rulesInfo.rulesData = res.data.data
            setTimeout(()=>{
              this.loading =false
            },300)
          }else {
            this.rulesInfo.rulesData= [{
              code: '',
              details: {
                time: '',
                unit: '',
                val: ''
              }
            }]
            setTimeout(()=>{
              this.loading =false
            },300)
          }
        }else {
          setTimeout(()=>{
            this.loading =false
          },300)
        }
      })
    },
    // 获取字典
    Dictionary() {
      this.dictionaryData = []
      this.contentData = []
      this.dictionary_default = []
      this.content_default = []
      let url= this.$api.Dictionary + '?key=gold_event_type';
      this.$http.get(url, true).then(res =>{
        if(res.data.success) {
          let info = res.data.data
          for(let i in info) {
            if(info[i].max === 1){
              this.dictionaryData.push({
                label: info[i].title,
                value: i
              })
            }else {
              this.contentData.push({
                label: info[i].title,
                value: i,
                max:info[i].max
              })
            }
          }
          this.dictionary_default= JSON.parse(JSON.stringify(this.dictionaryData))
          this.content_default= JSON.parse(JSON.stringify(this.contentData))
        }
      })
    },
    // 类型聚焦
    dictionaryFocus(index) {
      let current_val= this.rulesInfo.rulesData

      for(let j=0; j<current_val.length; j++) {
        for (let i=0; i< this.dictionaryData.length; i++) {
          if((current_val[j].code === this.dictionaryData[i].value) && index !== j) {
            this.dictionaryData.splice(i, 1)
            if (this.dictionaryData.length === 1){
              this.addDisplay = false
            }
          }
        }
        for (let i=0; i< this.contentData.length; i++) {
          if((current_val[j].code === this.contentData[i].value) && index !== j){
            this.contentData[i].max--
            if(this.contentData[i].max === 0){
              this.contentData.splice(i, 1)
            }
            if (this.contentData.length === 1 &&  this.contentData[0].max === 1){
              this.addDisplay = false
            }
          }
        }
      }
    },
    // 类型失焦
    dictionaryBlur(value, index) {
      if(value) {
        this.dictionaryFocus(index)
      }else {
          this.dictionaryData= JSON.parse(JSON.stringify(this.dictionary_default))
          this.contentData= JSON.parse(JSON.stringify(this.content_default))
      }
    },
    //删除规则
    removeDomain(index, type) {
      if( (0 === this.rulesInfo.rulesData.length -1) && type != 'all') {
        return false
      }else {
        if(this.rulesInfo.rulesData[index].id){
          let url = this.$api.Rules + '/'+ this.ruleid + this.$api.ruleItem + `/${this.rulesInfo.rulesData[index].id}`;
          this.$http.del(url,true).then(res => {
            if (res.data.success) {
              this.$message.success('删除成功')
              this.rulesInfo.rulesData.splice(index, 1)
              this.addDisplay = true
              if(this.rulesInfo.rulesData.length == 0 && type == 'all') {
                this.rulesInfo.rulesData.push({
                  code: '',
                  details: {
                    time: '',
                    unit: '',
                    val: ''
                  }
                });
              }
              // this.tabsrule()
            }else {
              this.$message.error(res.data.message)
            }
          }).catch(error =>{
            this.$message.error(error.response.data.message)
          })
        }else {
          this.rulesInfo.rulesData.splice(index, 1)
          if(this.rulesInfo.rulesData.length == 0 && type == 'all') {
            this.rulesInfo.rulesData.push({
              code: '',
              details: {
                time: '',
                unit: '',
                val: ''
              }
            });
          }
          this.addDisplay = true
        }
      }
    },
    tabsrule(){
      if(this.activeName === 'first'){
        this.getRlueInfo(1)
      }else if(this.activeName === 'two'){
        this.getRlueInfo(0)
      }
    },
    //新增规则
    addDomain() {
      if (this.rulesInfo.rulesData[this.rulesInfo.rulesData.length-1].code !== ''){
        this.rulesInfo.rulesData.push({
          code: '',
          details: {
            time: '',
            unit: '',
            val: ''
          }
        });
      }

    },
    // 取消保存
    cancelBtn: _.debounce(function () {
      this.Dictionary()
      this.getRules()
    },500),
    // 保存数据
    saveSubmit: _.debounce(function() {
      this.$refs[this.activeName].validate((valid) => {
        if (valid) {
          if(this.activeName === 'three'){
            let url= this.$api.Rules +'/'+ this.ruleid;
            let data= {
              control_config: this.rulesInfo.control_config
            }
            this.$http.put(url, data, true).then(res =>{
              if(res.data.success) {
                this.tabsrule()
                this.$message.success('保存成功')
              }else {
                this.$message.error(res.data.message)
              }
            }).catch(error =>{
              this.$message.error(error.response.data.message)
            })
          }else{
            let url= this.$api.Rules +'/'+ this.ruleid + this.$api.storeOrUpdate;
            let data= {
              rule_items: this.rulesInfo.rulesData
            }
            this.$http.post(url, data, true).then(res =>{
              if(res.data.success) {
                this.tabsrule()
                this.$message.success('保存成功')
              }else {
                this.$message.error(res.data.message)
              }
            }).catch(error =>{
              this.$message.error(error.response.data.message)
            })
          }
        } else {
          return false;
        }
      });

    },500),
    handleClick(tab, event) {
      this.rulesInfo.rulesData = []
      this.addDisplay = true
      if (tab.name === 'first'){
        this.getRlueInfo(1)
      }else if(tab.name === 'two'){
        this.getRlueInfo(0)
      }
      this.linkFun()
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({path: '/rule',query: {
          activeName: this.activeName,
        }})
    },
    // 参数赋值
    queryFun() {
      let query= this.$route.query;
      if(query) {
        if(query.activeName) {
          this.activeName= query.activeName;
        }
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import "~assets/less/rule/index.less";
</style>
